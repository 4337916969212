// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__container__GwFHUr{
    width: 344px;
    padding: 24px;
}
.style__tagContainer__bhiMkj{
    padding: 4px;
    border: 1px solid #cccccc;
}
.style__tagContainer__bhiMkj:hover{
    border-color: #0075ff;
}
.style__tagContainerInvalid__FQceN4{
    padding: 4px;
    border: 1px solid #ef5350;
}
.style__tagContainerInvalid__FQceN4:hover{
    border-color: #0075ff;
}
.style__tag__drSby6{
    position: relative;
    display: block;
    gap: 6px;
    background-color: #E8F5E9;
    padding: 2px 8px;
    font-size: 14px;
    line-height: 20px;
    color: #212121;
    border-radius: 4px;
    max-width: 286px;
    overflow: hidden;
	text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 22px; 
}
.style__tagRemove__nUyMno{
    width: 8px;
    height: 8px;
    position: absolute;
    border-radius: 8px;
    top: 8px;
    right: 8px;
    background-color: inherit;
    box-shadow: none;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
.style__tagWrapper__aWLeoi{
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}
.style__tag__drSby6.style__invalidTag__qh6Jke{
    background-color: #FFEBEE;
}

.style__tagRemove__nUyMno::before{
    content: "";
    background-color: #616161; 
    position: absolute;
    width: 10px;
    height: 2px;
    border-radius: 4px;
    transform: rotate(45deg);
    top: 3px;
    left: 0;
}
.style__tagRemove__nUyMno::after{
    content: "";
    background-color: #616161; 
    position: absolute;
    width: 10px;
    height: 2px;
    border-radius: 4px;
    transform: rotate(135deg);
    top: 3px;
    left: 0;
}
.style__tagInputField__jmNmxT{
    border: none;
    padding-left: 2px;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
}
.style__tagInput__YrY1lq{
    width: 100%;
}
.style__tagInputField__jmNmxT:focus{
    border: none!important;
}

.style__tagLabel__rdE4Bu{
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #424242;
    margin-bottom: 8px;
}


.style__buttonContainer__RiKGzW{
    align-items: center;
    display: flex;
    gap: 16px;
}

.style__formWrapper__QeTkUU{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
}

.style__formTitle__nLexPf{
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    color: #212121;
}
.style__tagLabelWrapper__mSc2Cq{
    display: flex;
    gap: 5px;
    align-items: center;
}

.style__errorMessage__eExV0b{
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 200;
    line-height: 16px;
    color: #ef5350;
    padding-top: 4px;
    margin-bottom: 0;
    margin-top: 4px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style__container__GwFHUr`,
	"tagContainer": `style__tagContainer__bhiMkj`,
	"tagContainerInvalid": `style__tagContainerInvalid__FQceN4`,
	"tag": `style__tag__drSby6`,
	"tagRemove": `style__tagRemove__nUyMno`,
	"tagWrapper": `style__tagWrapper__aWLeoi`,
	"invalidTag": `style__invalidTag__qh6Jke`,
	"tagInputField": `style__tagInputField__jmNmxT`,
	"tagInput": `style__tagInput__YrY1lq`,
	"tagLabel": `style__tagLabel__rdE4Bu`,
	"buttonContainer": `style__buttonContainer__RiKGzW`,
	"formWrapper": `style__formWrapper__QeTkUU`,
	"formTitle": `style__formTitle__nLexPf`,
	"tagLabelWrapper": `style__tagLabelWrapper__mSc2Cq`,
	"errorMessage": `style__errorMessage__eExV0b`
};
export default ___CSS_LOADER_EXPORT___;
