import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "classnames";
import styles from "./style/style.module.css";
export var BUTTON_TYPES;
(function (BUTTON_TYPES) {
    BUTTON_TYPES["button"] = "button";
    BUTTON_TYPES["reset"] = "reset";
    BUTTON_TYPES["submit"] = "submit";
})(BUTTON_TYPES || (BUTTON_TYPES = {}));
export var BUTTON_SIZE;
(function (BUTTON_SIZE) {
    BUTTON_SIZE["big"] = "big";
    BUTTON_SIZE["medium"] = "medium";
    BUTTON_SIZE["small"] = "small";
    BUTTON_SIZE["smallApply"] = "smallApply";
})(BUTTON_SIZE || (BUTTON_SIZE = {}));
export var BUTTON_COLOR_SCHEME;
(function (BUTTON_COLOR_SCHEME) {
    BUTTON_COLOR_SCHEME["accept"] = "accept";
    BUTTON_COLOR_SCHEME["acceptTransparent"] = "acceptTransparent";
    BUTTON_COLOR_SCHEME["closeTransparent"] = "closeTransparent";
    BUTTON_COLOR_SCHEME["dangerous"] = "dangerous";
    BUTTON_COLOR_SCHEME["dangerousTransparent"] = "dangerousTransparent";
})(BUTTON_COLOR_SCHEME || (BUTTON_COLOR_SCHEME = {}));
export var Button = function (_a) {
    var label = _a.label, onClick = _a.onClick, disabled = _a.disabled, type = _a.type, size = _a.size, colorScheme = _a.colorScheme, name = _a.name, customStyle = _a.customStyle;
    return (_jsx("button", { className: classNames(styles.btn, styles[size], styles[colorScheme], customStyle), "aria-label": "button-".concat(name ? name : size + " " + colorScheme), disabled: disabled, name: name, type: type, onClick: onClick, children: label }));
};
