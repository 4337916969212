export var WorkspaceTypes;
(function (WorkspaceTypes) {
    WorkspaceTypes["LIST_WORKSPACES_ADMIN_REQUEST"] = "LIST_WORKSPACES_ADMIN_REQUEST";
    WorkspaceTypes["LIST_WORKSPACES_ADMIN_SUCCESS"] = "LIST_WORKSPACES_ADMIN_SUCCESS";
    WorkspaceTypes["CREATE_WORKSPACE_REQUEST"] = "CREATE_WORKSPACE_REQUEST";
    WorkspaceTypes["CREATE_WORKSPACE_SUCCESS"] = "CREATE_WORKSPACE_SUCCESS";
    WorkspaceTypes["GET_WORKSPACES_ADMIN_REQUEST"] = "GET_WORKSPACES_ADMIN_REQUEST";
    WorkspaceTypes["GET_WORKSPACES_ADMIN_SUCCESS"] = "GET_WORKSPACES_ADMIN_SUCCESS";
    WorkspaceTypes["GET_WORKSPACE_REQUEST"] = "GET_WORKSPACE_REQUEST";
    WorkspaceTypes["GET_WORKSPACE_SUCCESS"] = "GET_WORKSPACE_SUCCESS";
    WorkspaceTypes["GET_WORKSPACE_DISABLE"] = "GET_WORKSPACE_DISABLE";
    WorkspaceTypes["UPDATE_ADMIN_WORKSPACE_REQUEST"] = "UPDATE_ADMIN_WORKSPACE_REQUEST";
    WorkspaceTypes["UPDATE_ADMIN_WORKSPACE_SUCCESS"] = "UPDATE_ADMIN_WORKSPACE_SUCCESS";
    WorkspaceTypes["LIST_WORKSPACE_NAMES_SUCCESS"] = "LIST_WORKSPACE_NAMES_SUCCESS";
    WorkspaceTypes["LIST_WORKSPACE_NAMES_REQUEST"] = "LIST_WORKSPACE_NAMES_REQUEST";
    WorkspaceTypes["ACCEPT_INVITE_REQUEST"] = "ACCEPT_INVITE_REQUEST";
    WorkspaceTypes["ACCEPT_INVITE_SUCCESS"] = "ACCEPT_INVITE_SUCCESS";
    WorkspaceTypes["ACCEPT_INVITE_ERROR"] = "ACCEPT_INVITE_ERROR";
    WorkspaceTypes["DELETE_INVITE_REQUEST"] = "DELETE_INVITE_REQUEST";
    WorkspaceTypes["DELETE_INVITE_SUCCESS"] = "DELETE_INVITE_SUCCESS";
    WorkspaceTypes["DELETE_INVITE_ERROR"] = "DELETE_INVITE_ERROR";
})(WorkspaceTypes || (WorkspaceTypes = {}));
