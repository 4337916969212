import React from "react";
import { Translate, I18n } from "react-redux-i18n";
// eslint-disable-next-line no-unused-vars
import translate from "../css/html-translate.css";

export default class HtmlTranslate extends Translate {
  static defaultProps = {
    innerStyleName: "translate.text",
  };

  render = () => (
    <span
      className={this.props.classNames}
      styleName={this.props.innerStyleName}
      dangerouslySetInnerHTML={{
        __html: I18n._translate(this.props.value, this.otherProps()),
      }}
    ></span>
  );
}
