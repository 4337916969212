import nodeUrl from "url";

export const getIsAIFromUrl = url => {
  const currentUrl = url ? url : window.location.href;
  const isAi = nodeUrl.parse(currentUrl, true, true).query.ai;

  const result = isAi && isAi === "true";
  return result;
};

export const getCouponFromUrl = url => {
  const currentUrl = url ? url : window.location.href;
  const coupon = nodeUrl.parse(currentUrl, true, true).query.coupon;

  return coupon && coupon.trim();
};

export const getDiscountFromUrl = url => {
  const currentUrl = url ? url : window.location.href;
  const discount = nodeUrl.parse(currentUrl, true, true).query.discount;

  return discount && discount.trim();
};

export const getUuidFromUrl = url => {
  const currentUrl = url ? url : window.location.href;
  const discount = nodeUrl.parse(currentUrl, true, true).query.uuid;

  return discount && discount.trim();
};

export const getQueryValueFromUrl = (url, queryField = "") => {
  const urlSearchParams = new URLSearchParams(url || window.location.search);
  const queryValue = urlSearchParams.get(queryField);
  return queryValue && queryValue.trim();
};

export const getNavigateURLForCouponDiscountQueries = (baseUrl, coupon, discount) => {
  if (coupon) return `${baseUrl}?coupon=${coupon}`;
  if (discount) return `${baseUrl}?discount=${discount}`;

  return baseUrl;
};
