//@ts-nocheck
import { showError, showWarningAutoHide } from "../thunks/Notification";
import { cursorWait } from "../settings/cursorWait";
import { I18n } from "react-redux-i18n";
import { localStorageService } from "../services/LocalStorageService";
var POSITIVE_RESPONSE_CODE = 200;
var POSITIVE_DELETE_CODE = 204;
export var POSITIVE_CODES = [POSITIVE_RESPONSE_CODE, POSITIVE_DELETE_CODE];
var promisify = function (promiseCallback) { return new Promise(promiseCallback); };
export var newHttpRequest = function (requestOptions, token, dispatch, cursorWatch, isFileService) {
    return promisify(function (resolve, reject) {
        var isOnline = window.navigator.onLine === undefined || window.navigator.onLine;
        if (isOnline) {
            var url = requestOptions.url, method = requestOptions.method, type = requestOptions.type, headers = requestOptions.headers, body = requestOptions.body;
            cursorWait(cursorWatch);
            var input = (isFileService ? process.env.FILE_SERVICES_URL : process.env.SERVICES_URL) + url;
            var workspaceId = localStorageService.getWorkspaceId();
            return fetch(input, {
                method: method,
                headers: headers
                    ? headers
                    : {
                        Accept: "application/json, text/plain, image/jpeg, */*",
                        "Content-Type": "application/json",
                        Authorization: "bearer " + token,
                        "Workspace-Id": workspaceId ? workspaceId : "",
                    },
                body: body,
            }).then(function (response) {
                cursorWait(false);
                return POSITIVE_CODES.includes(response.status)
                    ? resolve(response)
                    : reject({
                        error: {
                            status: response.status,
                            text: response.text(),
                        },
                    });
            }, function (error) {
                cursorWait(false);
                dispatch(showError(I18n._translate("ERRORS.COMMON_REFRESH")));
                reject({ error: error });
            });
        }
        else {
            dispatch(showWarningAutoHide(I18n._translate("ERRORS.FAILED_CONNECTION")));
            cursorWait(false);
            reject({ error: I18n._translate("ERRORS.FAILED_CONNECTION") });
        }
    });
};
