import * as yup from "yup";
export var generateCompanyUrl = function (name) {
    return name
        .toLowerCase()
        .replace(/\s+/g, "")
        .replace(/[^a-z0-9]/g, "");
};
export var validationSchema = function (clientEmail) {
    return yup.object().shape({
        companyName: yup.string().required("Required field"),
        companyUrl: yup
            .string()
            .required("Required field")
            .matches(/^[a-z0-9]+$/, "Invalid value. The link to the workspace can only contain Latin letters or numbers"),
        userEmails: yup
            .array()
            .of(yup.string().email("Invalid email format"))
            .test("no-user-email", "You can’t send an invitation to yourself", function (emails) {
            return !(emails === null || emails === void 0 ? void 0 : emails.includes(clientEmail));
        })
            .test("invalid-emails", "One or more emails are invalid", function (emails) {
            return emails === null || emails === void 0 ? void 0 : emails.every(function (email) { return yup.string().email().isValidSync(email); });
        }),
    });
};
