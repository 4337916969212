// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style-module__btn__s_JLKc {
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    box-shadow: none;
    border: none;
    border-radius: 3px;
    transition: background-color 100ms ease-in-out;
}

.style-module__btn__s_JLKc:disabled {
    pointer-events: none;
    background-color: var(--gray-10);
    color: var(--gray-40);
    border-color: var(--gray-40);
}

/* SIZES */
.style-module__big__EC9nhk {
    padding: 10px 20px;
    height: 40px;

}

.style-module__medium__Rxe2c2 {
    padding: 7px 20px;
    height: 32px;
    font-size: 16px;
    line-height: 18px;
}

.style-module__small__F1bDDT {
    padding: 2px 12px;
    height: 24px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
}
.style-module__smallApply__pXDdkn {
    padding: 2px 12px;
    height: 24px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    /* add custom property */
    margin-right: 16px;
}

/* COLOR SCHEMES */
/* accept */
.style-module__accept__f89wbF {
    color: #ffffff;
    background-color: #43a047;
}

.style-module__accept__f89wbF:hover {
    background-color: #388e3c;
}

.style-module__acceptTransparent__ZEE5UE {
    color: var(--green-60);
    background-color: var(--default-white);
    border: 1px solid var(--green-60);
    transition: color 100ms ease-in-out, border-color 100ms ease-in-out; 
}

.style-module__acceptTransparent__ZEE5UE:hover {
    color: var(--green-70);
    border-color: var(--green-70);
}

/* dangerous */

.style-module__dangerous__B1ysGb {
    color: var(--default-white);
    background-color: var(--red-40);
}

.style-module__dangerous__B1ysGb:hover {
    background-color: var(--red-60);
}

.style-module__dangerousTransparent__mUtN0M {
    color: var(--red-40);
    background-color: var(--default-white);
    border: 1px solid var(--red-40);
}

.style-module__dangerousTransparent__mUtN0M:hover {
    color: var(--red-60);
    border-color: var(--red-60);
}

/* cancel */
.style-module__closeTransparent__TagEe3 {
    cursor: pointer;
    color: #9e9e9e;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    text-decoration-color: #9e9e9e;
    background-color: #ffffff;
    font-weight: 300;
    padding: 0;
    transition: color 100ms ease-in-out;
}
/* .closeTransparent:hover{
    color: var(--green-70);
    text-decoration-color: var(--green-70);
} */
.style-module__closeTransparent__TagEe3:disabled{
    color: var(--gray-40);
    text-decoration-color: var(--gray-40);
    background-color: transparent;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `style-module__btn__s_JLKc`,
	"big": `style-module__big__EC9nhk`,
	"medium": `style-module__medium__Rxe2c2`,
	"small": `style-module__small__F1bDDT`,
	"smallApply": `style-module__smallApply__pXDdkn`,
	"accept": `style-module__accept__f89wbF`,
	"acceptTransparent": `style-module__acceptTransparent__ZEE5UE`,
	"dangerous": `style-module__dangerous__B1ysGb`,
	"dangerousTransparent": `style-module__dangerousTransparent__mUtN0M`,
	"closeTransparent": `style-module__closeTransparent__TagEe3`
};
export default ___CSS_LOADER_EXPORT___;
