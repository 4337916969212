import { createThunkReducer } from "../helpers/createThunkReducer";
import reduceReducers from "reduce-reducers";

const initialState = {
  ["me"]: { status: "", data: {} },
  ["ratesList"]: { status: "", data: {} },
  ["customerSubscribeStatus"]: { status: "", data: {} },
  ["paymentMethods"]: {
    status: "",
    data: {
      value: [],
      error: null,
      stripePromise: null,
    },
  },
};

const getMe = createThunkReducer("me", "GET_ME", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});
//todo может стоит в action или где то в названии отметить что это для переводчика?
const getRateList = createThunkReducer("ratesList", "GET_TRANSLATOR_RATES", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const getCustomerSubscribeStatus = createThunkReducer("customerSubscribeStatus", "GET_CUSTOMER_SUBSCRIBE_STATUS", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});
const getCustomerSubscribeStatusByAdmin = createThunkReducer(
  "customerSubscribeStatus",
  "GET_CUSTOMER_SUBSCRIBE_STATUS_BY_ADMIN",
  {
    handleSuccessAction: (state, payload) => {
      return { requestState: { data: payload } };
    },
  }
);
export function setActiveWorkspace(state = initialState, action) {
  switch (action.type) {
    case "GET_WORKSPACE_SUCCESS":
      return {
        ...state,
        me: {
          ...state.me,
          status: action.type,
          data: {
            ...state.me.data,
            activeWorkspace: action.payload,
          },
        },
      };
    case "GET_WORKSPACE_DISABLE":
      return {
        ...state,
        me: {
          ...state.me,
          status: action.type,
          data: {
            ...state.me.data,
            activeWorkspace: "",
          },
        },
      };
    default:
      return state;
  }
}
export function updatePaymentMethods(state = initialState, action) {
  switch (action.type) {
    case "GET_PAYMENT_METHODS_REQUEST":
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          status: "GET_PAYMENT_METHODS_REQUEST",
        },
      };
    case "GET_PAYMENT_METHODS_SUCCESS":
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          status: "GET_PAYMENT_METHODS_SUCCESS",
          data: {
            ...state.paymentMethods.data,
            value: action.payload,
            error: null,
          },
        },
      };
    case "GET_PAYMENT_METHODS_ERROR":
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          status: "GET_PAYMENT_METHODS_ERROR",
          data: {
            ...state.paymentMethods.data,
            error: action.payload,
          },
        },
      };
    case "SAVE_PAYMENT_METHOD": //same as get_..._success
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          status: action.type,
          data: {
            ...state.paymentMethods.data,
            value: action.payload,
            error: null,
          },
        },
      };
    case "DELETE_PAYMENT_METHOD": //same as get_..._success
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          status: action.type,
          data: {
            ...state.paymentMethods.data,
            value: action.payload,
            error: null,
          },
        },
      };
    case "SET_STRIPE_PROMISE":
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          data: {
            ...state.paymentMethods.data,
            stripePromise: action.payload,
          },
        },
      };
    default:
      return state;
  }
}

function initialReducer(state = initialState) {
  return state;
}

export const meReducer = reduceReducers(
  initialReducer,
  getMe,
  getRateList,
  getCustomerSubscribeStatus,
  getCustomerSubscribeStatusByAdmin,
  updatePaymentMethods,
  setActiveWorkspace
);
