import { WorkspaceTypes } from "./workspaceActionTypes";
export function requestListWorkspacesAdmin() {
    return {
        type: WorkspaceTypes.LIST_WORKSPACES_ADMIN_REQUEST,
    };
}
export function successListWorkspacesAdmin(payload) {
    return {
        type: WorkspaceTypes.LIST_WORKSPACES_ADMIN_SUCCESS,
        payload: payload,
    };
}
export function requestCreateWorkspace() {
    return {
        type: WorkspaceTypes.CREATE_WORKSPACE_REQUEST,
    };
}
export function successCreateWorkspace(payload) {
    return {
        type: WorkspaceTypes.CREATE_WORKSPACE_SUCCESS,
        payload: payload,
    };
}
export function requestGetWorkspacesAdmin() {
    return {
        type: WorkspaceTypes.GET_WORKSPACES_ADMIN_REQUEST,
    };
}
export function successGetWorkspacesAdmin(payload) {
    return {
        type: WorkspaceTypes.GET_WORKSPACES_ADMIN_SUCCESS,
        payload: payload,
    };
}
export function requestGetWorkspace() {
    return {
        type: WorkspaceTypes.GET_WORKSPACE_REQUEST,
    };
}
export function successGetWorkspace(payload) {
    return {
        type: WorkspaceTypes.GET_WORKSPACE_SUCCESS,
        payload: payload,
    };
}
export function disableGetWorkspace() {
    return {
        type: WorkspaceTypes.GET_WORKSPACE_DISABLE,
    };
}
export function requestUpdateAdminWorkspace() {
    return {
        type: WorkspaceTypes.UPDATE_ADMIN_WORKSPACE_REQUEST,
    };
}
export function successUpdateAdminWorkspace(payload) {
    return {
        type: WorkspaceTypes.UPDATE_ADMIN_WORKSPACE_SUCCESS,
        payload: payload,
    };
}
export function requestListWorkspaceNames() {
    return {
        type: WorkspaceTypes.LIST_WORKSPACE_NAMES_REQUEST,
    };
}
export function successListWorkspaceNames(payload) {
    return {
        type: WorkspaceTypes.LIST_WORKSPACE_NAMES_SUCCESS,
        payload: payload,
    };
}
export function requestAcceptInvite() {
    return {
        type: WorkspaceTypes.ACCEPT_INVITE_REQUEST,
    };
}
export function successAcceptInvite(payload) {
    return {
        type: WorkspaceTypes.ACCEPT_INVITE_SUCCESS,
        payload: payload,
    };
}
export function errorAcceptInvite(error) {
    return {
        type: WorkspaceTypes.ACCEPT_INVITE_ERROR,
        payload: error,
    };
}
export function requestDeleteInvite() {
    return {
        type: WorkspaceTypes.DELETE_INVITE_REQUEST,
    };
}
// ActionCreator для успешного завершения запроса на удаление приглашения
export function successDeleteInvite(payload) {
    return {
        type: WorkspaceTypes.DELETE_INVITE_SUCCESS,
        payload: payload,
    };
}
// ActionCreator для обработки ошибок запроса на удаление приглашения
export function errorDeleteInvite(error) {
    return {
        type: WorkspaceTypes.DELETE_INVITE_ERROR,
        payload: error,
    };
}
