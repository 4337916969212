import PropTypes from "prop-types";
import React, { Component } from "react";
// eslint-disable-next-line no-unused-vars
import menu from "./css/side-menu.css";
import MenuItemBalance from "./components/MenuItemBalance";
import MenuItemDesktopCount from "./components/MenuItemDesktopCount";
import MenuItem from "./components/MenuItem/MenuItem";
import { I18n } from "react-redux-i18n";
import moment from "moment";
import IcomoonReact from "icomoon-react";
import iconSet from "../../../images/selection.json";
import { withRouter } from "../../../containers/CustomerApp";

class SideMenu extends Component {
  static propTypes = {
    balance: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      [this.props.menuItems[0].directoryId]: true,
    };
  }

  showSideMenu(showSideMenu) {
    if (showSideMenu) {
      const body = document.getElementsByTagName("body")[0];

      body.onresize = () => {
        this.props.toggleSideMenu(false);
      };

      body.onmouseup = event => {
        if (
          (!document.getElementById("side-menu").contains(event.target) ||
            document.getElementById("side-menu-close").contains(event.target) ||
            document.querySelector("#menu-list").contains(event.target)) &&
          event.target !== document.getElementById("directory") &&
          event.target.parentElement !== document.getElementById("directory")
        ) {
          this.closeSideMenu();
        }
      };

      return "flex";
    } else {
      const body = document.getElementsByTagName("body")[0];

      body.onresize = null;
      body.onmouseup = null;
      return "none";
    }
  }

  componentWillUnmount() {
    const body = document.getElementsByTagName("body")[0];

    body.onresize = null;
    body.onmouseup = null;
  }

  componentDidUpdate() {
    window.onpopstate = () => {
      this.closeSideMenu();
    };
  }

  closeSideMenu = () => {
    this.props.toggleSideMenu(false);
  };

  setActiveMenuItem = pathname => {
    return this.props.router.location.pathname.split("/")[2] === pathname ? "menu.active" : "";
  };

  render() {
    const { menuItems } = this.props;
    const display = this.showSideMenu(this.props.display);

    const commit = { COMMIT_DATE }.COMMIT_DATE.split("T");
    const commitTime = commit[1].split("+");
    const commitTmp = commit[0].split("-");
    const commitDate = commitTmp[2] + "/" + commitTmp[1] + "/" + commitTmp[0] + " " + commitTime[0];
    const version = { VERSION }.VERSION;
    const buildData = JSON.parse(localStorage.getItem("buildData"));
    return (
      <div id="side-menu" styleName={"menu.side-menu"} style={{ display: display, height: window.innerHeight }}>
        <a styleName={"menu.close-icon"} id="side-menu-close" onClick={this.closeHandler}>
          <IcomoonReact iconSet={iconSet} color={"#FFFFFF"} size={17} icon={"menu"} />
        </a>
        <ul id="menu-list" styleName={"menu.menu-list"}>
          {menuItems[0].route
            ? menuItems.map((item, i) => {
                if (item.balance || item.balance === 0) {
                  return (
                    <MenuItemBalance
                      key={i}
                      link={item.route}
                      pageHeading={item.content}
                      content={item.balance}
                      active={this.setActiveMenuItem(item.route)}
                      setAnchor={item.setAnchor}
                      scrollToAddFunds={item.scrollToAddFunds}
                    />
                  );
                } else if (
                  (item.desktopCount && item.desktopCount > +0) ||
                  (item.overdue && item.overdue > +0) ||
                  item.unreadStatus
                ) {
                  const content = item.desktopCount ? item.desktopCount : item.overdue;
                  return (
                    <MenuItemDesktopCount
                      key={i}
                      link={item.route}
                      pageHeading={item.content}
                      content={content}
                      iconIsWhite={item.iconIsWhite}
                      active={this.setActiveMenuItem(item.route)}
                      unreadStatus={item.unreadStatus}
                    />
                  );
                } else {
                  return (
                    <MenuItem
                      key={i}
                      link={item.route}
                      scrollTo={item.scrollTo}
                      setAnchor={item.setAnchor}
                      pageHeading={item.content}
                      active={this.setActiveMenuItem(item.route)}
                    />
                  );
                }
              })
            : menuItems.map((directory, directoryIndex) => {
                return (
                  <div styleName={"menu.directoryContainer"} key={directoryIndex}>
                    <div
                      id={"directory"}
                      styleName={"menu.directory"}
                      onClick={() => {
                        this.setState({
                          [directory.directoryId]: !this.state[directory.directoryId],
                        });
                      }}
                    >
                      <div id={"directoryName"} styleName={"menu.directoryName"}>
                        {I18n.t(directory.directoryName)}
                      </div>
                      {this.state[directory.directoryId] ? (
                        <div styleName={"menu.arrowUp"}>
                          <IcomoonReact iconSet={iconSet} color={"#FFFFFF"} size={11} icon={"up_arrow"} />
                        </div>
                      ) : (
                        <div styleName={"menu.arrowDown"}>
                          <IcomoonReact iconSet={iconSet} color={"#FFFFFF"} size={11} icon={"down_arrow"} />
                        </div>
                      )}
                    </div>
                    {this.state[directory.directoryId] &&
                      directory.directories.map((item, i) => {
                        if (item.balance || item.balance === 0) {
                          return (
                            <MenuItemBalance
                              key={i}
                              link={item.route}
                              pageHeading={item.content}
                              content={item.balance}
                              active={this.setActiveMenuItem(item.route)}
                              setAnchor={item.setAnchor}
                              scrollToAddFunds={item.scrollToAddFunds}
                            />
                          );
                        } else if (
                          (item.desktopCount && item.desktopCount > +0) ||
                          (item.overdue && item.overdue > +0) ||
                          item.unreadStatus
                        ) {
                          const content = item.desktopCount ? item.desktopCount : item.overdue;
                          return (
                            <MenuItemDesktopCount
                              key={i}
                              link={item.route}
                              pageHeading={item.content}
                              content={content}
                              iconIsWhite={item.iconIsWhite}
                              active={this.setActiveMenuItem(item.route)}
                              unreadStatus={item.unreadStatus}
                            />
                          );
                        } else {
                          return (
                            <MenuItem
                              key={i}
                              link={item.route}
                              scrollTo={item.scrollTo}
                              setAnchor={item.setAnchor}
                              pageHeading={item.content}
                              active={this.setActiveMenuItem(item.route)}
                            />
                          );
                        }
                      })}
                  </div>
                );
              })}
        </ul>
        <div styleName={"menu.hash-container"}></div>
        <div styleName={"menu.hash-version"}>
          {I18n.t("SIDEMENU.COMMIT_VERSION_DATE", {
            frontVersion: version,
            frontDate: commitDate,
            backVersion: buildData["build-hash"],
            backDate: moment(parseInt(buildData["build-time"])).format("DD/MM/YYYY HH:mm:ss"),
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(SideMenu);
