var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styles from "./style/style.module.css";
import classNames from "classnames";
export var Input = React.forwardRef(function (_a, ref) {
    var _b;
    var label = _a.label, value = _a.value, type = _a.type, error = _a.error, disabled = _a.disabled, defaultValue = _a.defaultValue, placeholder = _a.placeholder, id = _a.id, name = _a.name, fixedValue = _a.fixedValue, props = __rest(_a, ["label", "value", "type", "error", "disabled", "defaultValue", "placeholder", "id", "name", "fixedValue"]);
    return (_jsxs("label", { className: styles.wrapper, htmlFor: id, children: [_jsx("p", { className: styles.inputLabel, children: label }), _jsxs("div", { className: classNames(styles.inputFieldWrapper, (_b = {},
                    _b[styles.invalid] = error,
                    _b)), children: [fixedValue && _jsx("span", { className: styles.inputDefaultText, children: fixedValue }), _jsx("input", __assign({ ref: ref, id: id, className: styles.inputField, "aria-label": label, defaultValue: defaultValue, disabled: disabled, name: name, placeholder: placeholder, type: "text", value: value }, props))] }), error && _jsx("p", { className: styles.errorMessage, children: error })] }));
});
Input.displayName = "Input";
