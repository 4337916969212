import PropTypes from "prop-types";
import React, { Component } from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import UserMenu from "../UserMenu";
import HtmlTranslate from "../HtmlTranslate";
import FixedTop from "./../../components/layout/FixedTop";
import { Link } from "react-router-dom";
import "../../actions/AuthorizedActions";

import "../../css/deprecated/header.css";
import { changeTableState } from "../../actions/customer/NewOrder";
import IcomoonReact from "icomoon-react";
import iconSet from "../../images/selection.json";

const MIN_SPACE_WIDTH = 32;
const MAX_SPACE_WIDTH = 210;

export default function createHeader(appName) {
  function mapDispatchToProps(dispatch) {
    return {
      changeTableState: state => {
        return dispatch(changeTableState(state));
      },
    };
  }

  function mapStateToProps() {
    return {};
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class extends Component {
      static propTypes = {
        user: PropTypes.object.isRequired,
        menuToggler: PropTypes.func,
      };

      state = {
        isDividerHover: false,
      };

      openSideMenu = () => {
        this.props.menuToggler(true);
      };

      openNewOrder = () => {
        if (window.location.pathname === "/customer/new") {
          this.props.changeTableState({
            isClearForm: true,
          });
        }
      };

      onHover = isDividerHover => {
        this.setState({ isDividerHover });
      };

      checkSpacing = () => {
        const logoElement = document.getElementById("logoSpanWrapper");
        const userMenuElement = document.getElementById("userMenu");
        const supportLink = document.querySelector(".support-link");
        const userName = document.querySelector(".user-name");

        if (!logoElement || !userMenuElement) return;

        const spaceBetween = userMenuElement.getBoundingClientRect().left - logoElement.getBoundingClientRect().right;
        const currentWindowWidth = window.innerWidth;
        const isExpanding = currentWindowWidth > this.lastWindowWidth;

        if (isExpanding) {
          if (spaceBetween > 0 && spaceBetween > MAX_SPACE_WIDTH && userName.classList.contains("hidden")) {
            userName.classList.remove("hidden");
          } else if (spaceBetween > 0 && spaceBetween > MAX_SPACE_WIDTH && supportLink.classList.contains("hidden")) {
            supportLink.classList.remove("hidden");
          }
        } else {
          if (spaceBetween < MIN_SPACE_WIDTH && !supportLink.classList.contains("hidden")) {
            supportLink.classList.add("hidden");
          } else if (spaceBetween < MIN_SPACE_WIDTH && !userName.classList.contains("hidden")) {
            userName.classList.add("hidden");
          }
        }

        this.lastWindowWidth = currentWindowWidth;
      };

      componentDidMount() {
        this.checkSpacing();
        window.addEventListener("resize", this.checkSpacing);
      }

      componentWillUnmount() {
        window.removeEventListener("resize", this.checkSpacing);
      }

      render() {
        const { isDividerHover } = this.state;
        const { count, messageCount, iconIsWhite, user, desktop } = this.props;
        let desktopStatus = desktop && desktop.status;
        const iconColor = iconIsWhite ? "header_notifications__white" : "header_notifications__red";
        const shouldShowStarsForTranslator =
          user &&
          user.rolesList &&
          Array.isArray(user.rolesList) &&
          user.rolesList.includes(3) &&
          window.location.href.includes("/translator/");

        return (
          <FixedTop>
            <div className="header">
              <div style={{ flex: 1 }}>
                <div className="header-block menu-trigger">
                  <button onClick={this.openSideMenu} id="sidemenu-icon" className="burger-icon">
                    <IcomoonReact iconSet={iconSet} color={"#FFFFFF"} size={17} icon={"menu"} />
                  </button>
                  <div className="header_notifications">
                    {count > 0 && <span className={`${iconColor} header_notifications__base`}>{count}</span>}
                    {messageCount > 0 && <span className="header_notifications__messages">{messageCount}</span>}
                  </div>
                </div>
                {this.props.itemMenuNewOrder && (
                  <div
                    className="header-block divider"
                    onMouseEnter={() => this.onHover(true)}
                    onMouseLeave={() => this.onHover(false)}
                  >
                    <Link
                      to="new"
                      id="header-block newOrder"
                      onClick={this.openNewOrder}
                      className="header-block newOrder"
                      title={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.PAGE_HEADING")}
                    >
                      <IcomoonReact
                        iconSet={iconSet}
                        color={isDividerHover ? "#FFFFFF" : "#BDBDBD"}
                        size={16}
                        icon={"new_order"}
                      />
                    </Link>
                    <Link to="new" onClick={this.openNewOrder} className="header-block newOrderLabel">
                      {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.PAGE_HEADING")}
                    </Link>
                  </div>
                )}
              </div>

              <div className="header-block center">
                <a href="/select-role" className="logo">
                  <span id="logoSpanWrapper" style={{ display: "block" }}>
                    &nbsp;
                  </span>
                </a>
              </div>

              <div className="header-block user-menu" id="userMenu">
                <Link
                  to={`/${appName}/support`}
                  href={`/${appName}/support`}
                  id="support-menu"
                  className="support-menu"
                >
                  <span className="support-icon"></span>
                  <span className="support-link">
                    <HtmlTranslate value="HEADER.SUPPORT_LINK" />
                  </span>
                </Link>
                {shouldShowStarsForTranslator && (
                  <div
                    className="star-container"
                    title={I18n.t("TRANSLATOR_APP.HEADER", { counter: this.props.user.starState.available })}
                  >
                    <IcomoonReact iconSet={iconSet} size={20} icon={"star_icon"} />
                    <p className="star-conut">{this.props.user.starState.available}</p>
                  </div>
                )}
                <UserMenu desktopStatus={desktopStatus} user={this.props.user} feedback={true} appName={appName} />
              </div>
            </div>
          </FixedTop>
        );
      }
    }
  );
}
