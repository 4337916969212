// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAApSURBVHgBtc4hDgAACAJA9P9/1mJACzNwkTEGQGpwlhBkIe7ke2HxnGzUrxP4d1MqzAAAAABJRU5ErkJggg==", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newOrder__container__F7HKU4 {
  display: flex;
  margin-top: 95px;
  width: 100%;
  height: calc(100% - 95px);
  border-top: 1px solid #e0e0e0;
}

.newOrder__leftBlock__kWicfe {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  width: 50%;
  min-height: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}

.newOrder__sourceBlock__kp1a9i {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 16px 16px;
  border-bottom: 1px solid #e0e0e0;
}

.newOrder__sourceTitle__w7O0Oy {
  height: 16px;
}

.newOrder__sourceContent__j6aEE3 {
  min-height: 40px;
  padding-top: 16px;
}

.newOrder__hintBlock__Wzl7BG {
  display: flex;
  flex-direction: column;
  padding: 0;
  border: none;
  margin-top: 16px;
}

.newOrder__hintTitle__zRY7Kc {
  min-height: 16px;
}

.newOrder__hintContent__hY0pBd {
  padding-top: 16px;
  min-height: 40px;
}

.newOrder__rightBlock__WKWDzd {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}

.newOrder__dropdown-menu__EvjfwD {
  min-width: 280px;
  max-width: 462px;
  height: 30px;
  margin-top: 8px;
  display: flex;
}

.newOrder__languagesBlock__jlE4b9 {
  min-height: 562px;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 !important;
}

.newOrder__targetLanguages__funxA5 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.newOrder__notAvailableLanguagesMessage__RO4dGY {
  color: #212121;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
}

.newOrder__targetCheckboxBlock__SPr4i7 {
  padding-top: 24px;
}

.newOrder__selectTargetLanguagesMessage__AclaKU {
  padding: 0 24px 16px 0;
  color: #212121;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  min-width: 330px;
}

.newOrder__selectSourceLanguageMessage__iKKUI4 {
  padding: 16px 24px 16px 0;
  color: #212121;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
}

.newOrder__selectService__Dd79bQ {
    padding: 16px 24px 0 0;
    color: #212121;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    font-family: Roboto, sans-serif;
}

.newOrder__languagesContainer__BtGc7E {
  display: flex;
}

.newOrder__detailsBlocks__551R70 {
  min-height: 478px;
  min-width: 100%;
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  flex-direction: column;
  align-items: stretch;
}

.newOrder__qualityBlock__LEihbd {
  display: flex;
  min-height: 214px;
  min-width: 100%;
  width: auto;
  flex-shrink: 0;
  flex-grow: 1;
  border-bottom: 1px solid #e0e0e0;
}

.newOrder__quality__AqDwwu {
  padding: 24px 24px 16px 16px;
  border-right: 1px solid #e0e0e0;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 50%;
  width: auto;
}

.newOrder__duration__pNx6Sv {
  padding: 24px 24px 16px 16px;
  min-width: 50%;
  width: auto;
}

.newOrder__addFunds__G4ewzw {
  padding: 24px 24px 16px 16px;
  min-width: 50%;
  width: auto;
}

.newOrder__durationText__kVsjFz {
  color: #212121;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  white-space: pre-line;
}

.newOrder__priceBlock__rn99Wm {
  min-height: 128px;
  min-width: 100%;
  border-bottom: 1px solid #e0e0e0;
  padding: 24px 24px 16px 16px;
}

.newOrder__priceContent__hsqigC {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #424242;
  font-family: Roboto;
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
}

.newOrder__priceText__x6if_J {
  /* [WIP] Remove after merge*/
}

.newOrder__advansedLabel__h4MDpd {
  color: #424242;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 5px;
}

.newOrder__advancedSettingsWrapper__k4bIGR {
  display: flex;
  align-items: flex-end;
}

.newOrder__addFundsButton__mmFHyR {
  height: 15px;
  width: 15px;
  margin-left: 8px;
  cursor: pointer;
}

.newOrder__priceBalance__T6KyBW {
  color: #212121;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.newOrder__priceLink__OxFkQ9 {
  color: #1e88e5;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  font-family: Roboto;
  cursor: pointer;
  box-shadow: none;
  margin-left: 8px;
}

.newOrder__durationLink__Q9F9rP {
  color: #1e88e5;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  font-family: Roboto;
  cursor: pointer;
  box-shadow: none;
}

.newOrder__submit__Hh7eax {
  display: flex;
  flex-direction: column;
  min-height: 135px;
  min-width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.newOrder__menuContainer__N_KvwB {
  white-space: nowrap;
  display: flex;
  flex-flow: wrap;
  line-height: 16px;
  height: 16px;
}

.newOrder__source-title__RhnQK_ {
  height: 16px;
  margin-bottom: 18px;
}

.newOrder__submit-button-container__LcA5R6 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 47px !important;
}

.newOrder__separator__muTdze {
  width: 1px;
  background-color: #e0e0e0;
  height: 16px;
  margin-left: 6px;
  margin-right: 2px;
}

.newOrder__advancedBtn__uZoaIk {
  color: #1e88e5;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  border-bottom: 1px solid #1e88e5;
  cursor: pointer;
}

.newOrder__advancedLabel__Z7HwrD {
  margin-top: 25px;
  margin-bottom: 8px;
  color: #212121;
  font-family: Roboto;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: normal;
}

.newOrder__discountResultContainer__h6cYAU {
  display: flex;
  align-items: center;
}

.newOrder__discountResult__Mx0UOj {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  color: #9e9e9e;
}

.newOrder__discountRemove__yD076_ {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.newOrder__addFiles__giumtL {
  font-size: 14px;
  line-height: 16px;
  background: #ffffff;
  color: #1e88e5;
  border-color: #1e88e5;
  border-radius: 3px;
  height: 24px;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.newOrder__addFiles__giumtL:hover,
  .newOrder__addFiles__giumtL:active,
  .newOrder__addFiles__giumtL:focus {
    background: #1e88e5;
    border-color: #1e88e5;
  }

.newOrder__addFiles__giumtL:hover > svg, .newOrder__addFiles__giumtL:active > svg, .newOrder__addFiles__giumtL:focus > svg {
      fill: #ffffff;
    }

.newOrder__addFiles__giumtL:active:focus {
    background: #1e88e5;
    border-color: #1e88e5;
  }

.newOrder__addFiles__giumtL:active:focus > svg {
      fill: #ffffff;
    }

.newOrder__addFiles-triangle__tFs4hU {
  margin-left: 5px;
  width: 7px;
  height: 5px;
  stroke: transparent;
  fill: #1e88e5;
}

.newOrder__addFilesPrimal__FFzxTj {
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  background: #1e88e5;
  border-color: #1e88e5;
  border-radius: 3px;
  height: 32px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.newOrder__addFilesPrimal__FFzxTj:hover,
  .newOrder__addFilesPrimal__FFzxTj:active,
  .newOrder__addFilesPrimal__FFzxTj:focus {
    background: #1976d2;
    border-color: #1976d2;
  }

.newOrder__addFilesPrimal__FFzxTj:active:focus {
    background: #1976d2;
    border-color: #1976d2;
  }

.newOrder__plusIcon__SFkUha {
  width: 8px;
  height: 8px;
  margin-right: 8px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.newOrder__popupCategoryContent__qUcoiB {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}

.newOrder__customRadio__OSRFEY {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 300;
}

.newOrder__customRadio__OSRFEY input[type="radio"] {
    display: none;
  }

.newOrder__customRadio__OSRFEY .newOrder__customRadioButton__mOukHA {
    width: 24px;
    height: 24px;
    border: 1px solid #bdbdbd;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;
  }

.newOrder__customRadio__OSRFEY .newOrder__customRadioButton__mOukHA::before {
      content: "";
      width: 14px;
      height: 14px;
      background-color: #1e88e5;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.2s ease;
    }

.newOrder__customRadio__OSRFEY input[type="radio"]:checked + .newOrder__customRadioButton__mOukHA {
    border: 1px solid #1e88e5;
  }

.newOrder__customRadio__OSRFEY input[type="radio"]:checked + .newOrder__customRadioButton__mOukHA::before {
      transform: translate(-50%, -50%) scale(1);
    }

@media (max-width: 658px) {
  .newOrder__container__F7HKU4 {
    flex-direction: column;
    margin-top: 95px;
    width: 100%;
    height: calc(100% - 95px);
    border-top: 1px solid #e0e0e0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .newOrder__leftBlock__kWicfe {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    width: auto;
    height: 100%;
    flex-shrink: 0;
    flex-grow: 1;
  }

  .newOrder__rightBlock__WKWDzd {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    width: auto;
    height: auto;
    flex-shrink: 0;
    flex-grow: 1;
  }
}

@media (max-width: 1040px) {
  .newOrder__menuContainer__N_KvwB {
    height: 100%;
  }

  .newOrder__dropdown-menu__EvjfwD {
    min-width: 262px;
    max-width: 700px;
    padding-left: 0px;
  }
}

@media (min-width: 641px) {
  .newOrder__container__F7HKU4 {
    margin-top: 95px;
    width: 100%;
    height: calc(100% - 95px);
    border-top: 1px solid #e0e0e0;
  }

  .newOrder__leftBlock__kWicfe {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    width: 50%;
    min-height: 100%;
    height: auto;
    flex-shrink: 0;
    flex-grow: 1;
    border-right: 1px solid #e0e0e0;
  }

  .newOrder__rightBlock__WKWDzd {
    min-width: 600px;
    width: 50%;
    height: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

.newOrder__sourceFileDescription__kSJTko {
  font-size: 14px;
  line-height: 21px;
}

.newOrder__uploadButtonContainer__FBmDzT {
  padding: 10px 0 10px 0;
  color: #212121;
  font-size: 14px;
  font-family: "Roboto";
}

.newOrder__uploadButtonLabel__BrQZCc {
  cursor: pointer;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
}

.newOrder__uploadButtonLabelInput__akayp5 {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.newOrder__uploadButtonLabelInput__akayp5:focus {
    border: none !important;
  }

.newOrder__uploadButtonItem__Hp8_GH {
  display: flex;
  padding: 5px 16px 5px 16px;
}

.newOrder__uploadButtonItem__Hp8_GH .newOrder__googleDriveIcon__H_NUvy {
    margin-right: 12px;
    min-width: 20px;
    color: #bdbdbd;
    width: 20px;
    height: 19px;
  }

.newOrder__uploadButtonItem__Hp8_GH .newOrder__computerIcon__aSyhB0 {
    margin-right: 12px;
    min-width: 20px;
    color: #bdbdbd;
    width: 20px;
    height: 19px;
  }

.newOrder__uploadButtonItem__Hp8_GH:hover {
    color: #1e88e5;
    background: #e3f2fd;
  }

.newOrder__uploadButtonItem__Hp8_GH:hover .newOrder__computerIcon__aSyhB0 {
      color: #1e88e5;
    }

.newOrder__uploadButtonItem__Hp8_GH:hover .newOrder__googleDriveIcon__H_NUvy {
      color: #1e88e5;
    }

.newOrder__fileInput__iwHK4V {
  display: none !important;
  outline: none !important;
}

.newOrder__fileInput__iwHK4V:active {
  display: none !important;
  outline: none !important;
}

.newOrder__uploadInputContainer__yLgiqJ {
  display: flex;
  flex-direction: column;
}

.newOrder__uploadLabel__ElEiWM {
  font-size: 14px;
  color: #424242;
  margin-bottom: 5px;
}

.newOrder__uploadInput__vVKAFX {
  border: 1px solid #cecece;
  box-sizing: border-box;
  border-radius: 1px;
}

.newOrder__customPlaceholder__HhAPSu {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  margin-left: 19px;
  margin-top: 16px;
  align-items: center;
  margin-right: 34px;
  max-width: 594px;
}

.newOrder__customPlaceholderText__mBU9An {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #9e9e9e;
  margin-right: 8px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.newOrder__fileFormatLabel__Zm1Lvy {
  font-size: 14px;
  line-height: 21px;
  cursor: default;
  display: flex;
  white-space: nowrap;
  align-items: center;
}

a.newOrder__fileFormatLabel__Zm1Lvy {
  cursor: pointer;
  border: none !important;
  color: initial !important;
}

.newOrder__fileFormatContainer__hodRIL {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.newOrder__fileFormatIcon__ovarl8 {
  margin-right: 6px;
  width: 17px;
  height: 17px;
}

.newOrder__imageList__cxo8kY {
  display: grid;
  grid-template-columns: repeat(3, 188px);
  column-gap: 16px;
  row-gap: 16px;
  margin-top: 24px;
}

@media screen and (max-width: 720px) {
  .newOrder__customPlaceholderText__mBU9An {
    white-space: normal;
  }
  .newOrder__imageList__cxo8kY {
    grid-template-columns: repeat(2, 188px);
  }
}

@media screen and (max-width: 708px) {
  .newOrder__customPlaceholder__HhAPSu {
    max-width: 530px;
  }
}

@media screen and (max-width: 480px) {
  .newOrder__imageList__cxo8kY {
    display: grid;
    grid-template-columns: repeat(2, 136px);
    column-gap: 16px;
    row-gap: 16px;
    margin-top: 14px;
  }
}

`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `newOrder__container__F7HKU4`,
	"leftBlock": `newOrder__leftBlock__kWicfe`,
	"sourceBlock": `newOrder__sourceBlock__kp1a9i`,
	"sourceTitle": `newOrder__sourceTitle__w7O0Oy`,
	"sourceContent": `newOrder__sourceContent__j6aEE3`,
	"hintBlock": `newOrder__hintBlock__Wzl7BG`,
	"hintTitle": `newOrder__hintTitle__zRY7Kc`,
	"hintContent": `newOrder__hintContent__hY0pBd`,
	"rightBlock": `newOrder__rightBlock__WKWDzd`,
	"dropdown-menu": `newOrder__dropdown-menu__EvjfwD`,
	"languagesBlock": `newOrder__languagesBlock__jlE4b9`,
	"targetLanguages": `newOrder__targetLanguages__funxA5`,
	"notAvailableLanguagesMessage": `newOrder__notAvailableLanguagesMessage__RO4dGY`,
	"targetCheckboxBlock": `newOrder__targetCheckboxBlock__SPr4i7`,
	"selectTargetLanguagesMessage": `newOrder__selectTargetLanguagesMessage__AclaKU`,
	"selectSourceLanguageMessage": `newOrder__selectSourceLanguageMessage__iKKUI4`,
	"selectService": `newOrder__selectService__Dd79bQ`,
	"languagesContainer": `newOrder__languagesContainer__BtGc7E`,
	"detailsBlocks": `newOrder__detailsBlocks__551R70`,
	"qualityBlock": `newOrder__qualityBlock__LEihbd`,
	"quality": `newOrder__quality__AqDwwu`,
	"duration": `newOrder__duration__pNx6Sv`,
	"addFunds": `newOrder__addFunds__G4ewzw`,
	"durationText": `newOrder__durationText__kVsjFz`,
	"priceBlock": `newOrder__priceBlock__rn99Wm`,
	"priceContent": `newOrder__priceContent__hsqigC`,
	"priceText": `newOrder__priceText__x6if_J`,
	"advansedLabel": `newOrder__advansedLabel__h4MDpd`,
	"advancedSettingsWrapper": `newOrder__advancedSettingsWrapper__k4bIGR`,
	"addFundsButton": `newOrder__addFundsButton__mmFHyR`,
	"priceBalance": `newOrder__priceBalance__T6KyBW`,
	"priceLink": `newOrder__priceLink__OxFkQ9`,
	"durationLink": `newOrder__durationLink__Q9F9rP`,
	"submit": `newOrder__submit__Hh7eax`,
	"menuContainer": `newOrder__menuContainer__N_KvwB`,
	"source-title": `newOrder__source-title__RhnQK_`,
	"submit-button-container": `newOrder__submit-button-container__LcA5R6`,
	"separator": `newOrder__separator__muTdze`,
	"advancedBtn": `newOrder__advancedBtn__uZoaIk`,
	"advancedLabel": `newOrder__advancedLabel__Z7HwrD`,
	"discountResultContainer": `newOrder__discountResultContainer__h6cYAU`,
	"discountResult": `newOrder__discountResult__Mx0UOj`,
	"discountRemove": `newOrder__discountRemove__yD076_`,
	"addFiles": `newOrder__addFiles__giumtL`,
	"addFiles-triangle": `newOrder__addFiles-triangle__tFs4hU`,
	"addFilesPrimal": `newOrder__addFilesPrimal__FFzxTj`,
	"plusIcon": `newOrder__plusIcon__SFkUha`,
	"popupCategoryContent": `newOrder__popupCategoryContent__qUcoiB`,
	"customRadio": `newOrder__customRadio__OSRFEY`,
	"customRadioButton": `newOrder__customRadioButton__mOukHA`,
	"sourceFileDescription": `newOrder__sourceFileDescription__kSJTko`,
	"uploadButtonContainer": `newOrder__uploadButtonContainer__FBmDzT`,
	"uploadButtonLabel": `newOrder__uploadButtonLabel__BrQZCc`,
	"uploadButtonLabelInput": `newOrder__uploadButtonLabelInput__akayp5`,
	"uploadButtonItem": `newOrder__uploadButtonItem__Hp8_GH`,
	"googleDriveIcon": `newOrder__googleDriveIcon__H_NUvy`,
	"computerIcon": `newOrder__computerIcon__aSyhB0`,
	"fileInput": `newOrder__fileInput__iwHK4V`,
	"uploadInputContainer": `newOrder__uploadInputContainer__yLgiqJ`,
	"uploadLabel": `newOrder__uploadLabel__ElEiWM`,
	"uploadInput": `newOrder__uploadInput__vVKAFX`,
	"customPlaceholder": `newOrder__customPlaceholder__HhAPSu`,
	"customPlaceholderText": `newOrder__customPlaceholderText__mBU9An`,
	"fileFormatLabel": `newOrder__fileFormatLabel__Zm1Lvy`,
	"fileFormatContainer": `newOrder__fileFormatContainer__hodRIL`,
	"fileFormatIcon": `newOrder__fileFormatIcon__ovarl8`,
	"imageList": `newOrder__imageList__cxo8kY`
};
export default ___CSS_LOADER_EXPORT___;
