import React, { useEffect, useState } from "react";
import Popup from "../../../elements/Popup";
import style from "./css/style.css";
import { WithContext as ReactTags, SEPARATORS } from "react-tag-input";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import { Input } from "../../Input/Input";
import { BUTTON_COLOR_SCHEME, BUTTON_SIZE, BUTTON_TYPES, Button } from "../../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  createWorkspaceAction,
  getWorkspaceAction,
} from "../../../../thunks/newThunks/workspace/workspaceAsyncActions";
import { generateCompanyUrl, validationSchema } from "./helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import Question from "../../Title/components";
import { useNavigate } from "react-router";
import { getMeAction } from "../../../../thunks/newThunks/user/userAsyncActions";

export const PopupCreateWorkspace = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const customerEmail = useSelector(state => state.me.me.data.email);
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    reset,
  } = useForm({ resolver: yupResolver(validationSchema(customerEmail)) });

  //[WIP] need to fix, its bad practice to reset form, need to change popup wrapper
  useEffect(() => {
    return () => reset();
  }, [isOpen]);

  const onSubmit = async data => {
    try {
      const workspace = await dispatch(
        createWorkspaceAction({
          name: data.companyName,
          link: data.companyUrl,
          invites: data.userEmails,
        })
      );
      await dispatch(getMeAction());
      await dispatch(getWorkspaceAction({ id: workspace.id }));
      handleNavigation(workspace.link);
      onClose();
    } catch (e) {
      handleErrors(e);
    }
  };
  const handleNavigation = workspaceLink => {
    const currentPathArray = window.location.pathname.split("/");
    currentPathArray.splice(1, 1, workspaceLink);
    navigate(currentPathArray.join("/"));
  };
  const handleErrors = error => {
    const { serviceError } = error;
    if (serviceError) {
      serviceError.validationError.invalidFieldsList.forEach(elem => setError(elem.field, { message: elem.message }));
    }
  };
  const [isCompanyUrlModified, setIsCompanyUrlModified] = useState(false);

  return (
    <Popup isOpen={isOpen} onClose={onClose} className={style.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.formWrapper}>
          <p className={style.formTitle}>Create new workspace</p>
          <Input
            {...register("companyName")}
            id={"companyName"}
            onBlur={e => {
              const name = e.target.value;
              if (!isCompanyUrlModified && name) {
                const generatedUrl = generateCompanyUrl(name);
                setValue("companyUrl", generatedUrl);
              }
            }}
            error={errors?.["companyName"]?.message}
            label="Workspace name"
            name={"companyName"}
          />

          <Input
            {...register("companyUrl")}
            id={"companyUrl"}
            error={errors?.["companyUrl"]?.message}
            label="Workspace link"
            name={"companyUrl"}
            fixedValue="nitro.alconost.com/"
            onChange={e => {
              setIsCompanyUrlModified(true);
            }}
          />
          <Controller
            name={"userEmails"}
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <TagInput
                value={value}
                onChange={onChange}
                error={errors?.["userEmails"]?.message}
                label={
                  <div className={style.tagLabelWrapper}>
                    User invitation <Question question={"Some Text"} />
                  </div>
                }
              />
            )}
          />
        </div>
        <div className={style.buttonContainer}>
          <Button
            colorScheme={BUTTON_COLOR_SCHEME.accept}
            label={"Create"}
            name="save"
            size={BUTTON_SIZE.medium}
            type={BUTTON_TYPES.submit}
          />
          <Button
            colorScheme={BUTTON_COLOR_SCHEME.closeTransparent}
            label={"Cancel"}
            name="close"
            size={BUTTON_SIZE.small}
            type={BUTTON_TYPES.button}
            onClick={onClose}
          />
        </div>
      </form>
    </Popup>
  );
};

const RemoveTagButton = props => {
  const { onRemove, className } = props;
  return <button {...props} onClick={onRemove} className={classNames(className, [style.tagRemove])} />;
};

const TagInput = ({ onChange, value, error, label }) => {
  const [tags, setTags] = useState(value || []);
  const userEmail = useSelector(state => state.me.me.data.email);
  const handleDelete = i => {
    const newTags = tags.filter((tag, index) => index !== i);
    setTags(newTags);
    onChange(newTags);
  };

  const handleAddition = tag => {
    const isInvalidEmail = !validateEmail(tag.text);
    const isUserEmail = tag.text === userEmail;
    const newTags = [
      ...tags,
      { ...tag, className: isUserEmail ? style.invalidTag : isInvalidEmail ? style.invalidTag : "" },
    ];
    setTags(newTags);
    onChange(newTags.map(tag => tag.text));
  };

  const validateEmail = email => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div>
      <p className={style.tagLabel}>{label}</p>
      <ReactTags
        tags={tags}
        autofocus={false}
        handleDelete={handleDelete}
        separators={[SEPARATORS.SPACE, SEPARATORS.ENTER]}
        handleAddition={handleAddition}
        inputFieldPosition="inline"
        allowDragDrop={false}
        allowDeleteFromEmptyInput={true}
        removeComponent={RemoveTagButton}
        placeholder={tags.length === 0 && "Add user(s)"}
        classNames={{
          tag: style.tag,
          tags: error ? style.tagContainerInvalid : style.tagContainer,
          selected: style.tagWrapper,
          tagInputField: style.tagInputField,
          tagInput: style.tagInput,
        }}
      />
      {error && <p className={style.errorMessage}>{error}</p>}
    </div>
  );
};
