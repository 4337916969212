import { I18n } from "react-redux-i18n";
import {
  listWorkspacesAdminRequest,
  createWorkspaceRequest,
  getWorkspacesAdminRequest,
  getWorkspaceRequest,
  updateAdminWorkspaceRequest,
  listWorkspaceNamesRequest,
  acceptInviteRequest,
  deleteInviteRequest,
} from "../../../services/WorkspaceServices/requests";
import { showNotification } from "../../Notification";
import {
  requestListWorkspacesAdmin,
  successListWorkspacesAdmin,
  requestCreateWorkspace,
  successCreateWorkspace,
  requestGetWorkspacesAdmin,
  successGetWorkspacesAdmin,
  requestGetWorkspace,
  successGetWorkspace,
  disableGetWorkspace,
  requestUpdateAdminWorkspace,
  successUpdateAdminWorkspace,
  requestListWorkspaceNames,
  successListWorkspaceNames,
  errorAcceptInvite,
  errorDeleteInvite,
  requestAcceptInvite,
  requestDeleteInvite,
  successAcceptInvite,
  successDeleteInvite,
} from "./workspaceActionCreators";
import { localStorageService } from "../../../services/LocalStorageService";

export const listWorkspacesAdminAction =
  ({ limit, offset, search, sortField, sortOrder }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestListWorkspacesAdmin());

    const data = await listWorkspacesAdminRequest({ limit, offset, search, sortField, sortOrder, token });
    dispatch(successListWorkspacesAdmin(data));
    return data;
  };

export const createWorkspaceAction =
  ({ name, link, invites }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestCreateWorkspace());
    try {
      const data = await createWorkspaceRequest({ name, link, invites, token });
      localStorageService.setWorkspaceId(data.id);
      dispatch(showNotification("SUCCESS"));
      dispatch(successCreateWorkspace(data));
      return data;
    } catch (e) {
      throw e;
    }
  };

export const getWorkspacesAdminAction =
  ({ workspaceId }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestGetWorkspacesAdmin());

    const data = await getWorkspacesAdminRequest({ workspaceId, token });
    dispatch(successGetWorkspacesAdmin(data));
    return data;
  };

export const getWorkspaceAction =
  ({ id, link, name }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();
    if (!(id || link || name)) {
      localStorageService.setWorkspaceId("");
      return dispatch(disableGetWorkspace());
    }

    dispatch(requestGetWorkspace());
    localStorageService.setWorkspaceId("");
    const data = await getWorkspaceRequest({ id, link, name, token });
    localStorageService.setWorkspaceId(data.id);
    dispatch(successGetWorkspace(data));
    return data;
  };

export const updateAdminWorkspaceAction =
  ({ workspaceId, name, link, billTo, balanceOverdraftAllowed }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestUpdateAdminWorkspace());

    const data = await updateAdminWorkspaceRequest({
      workspaceId,
      name,
      link,
      billTo,
      balanceOverdraftAllowed,
      token,
    });
    dispatch(showNotification(I18n.t("ADMIN_APP.USERS_PAGE.EDIT_USER_SECTION.SUCCESS_MESSAGE")));
    dispatch(successUpdateAdminWorkspace(data));
    return data;
  };

export const listWorkspaceNamesAction = () => async (dispatch, getState) => {
  const {
    app: { token },
  } = getState();

  dispatch(requestListWorkspaceNames());

  const data = await listWorkspaceNamesRequest(token);
  dispatch(successListWorkspaceNames(data));
  return data;
};

export const acceptInviteAction = workspaceId => async (dispatch, getState) => {
  const {
    app: { token },
  } = getState();

  dispatch(requestAcceptInvite());

  try {
    const data = await acceptInviteRequest({ workspaceId, token });
    dispatch(successAcceptInvite(data));
    return data;
  } catch (error) {
    dispatch(errorAcceptInvite(error));
    throw error;
  }
};

export const deleteInviteAction = workspaceId => async (dispatch, getState) => {
  const {
    app: { token },
  } = getState();

  dispatch(requestDeleteInvite());

  try {
    const data = await deleteInviteRequest({ workspaceId, token });
    dispatch(successDeleteInvite(data));
    return data;
  } catch (error) {
    dispatch(errorDeleteInvite(error));
    throw error;
  }
};
