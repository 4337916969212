// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style-module__navMenuList__i3IjJ1{
    list-style: none;
    margin: 0;
    padding: 0;
    width: 260px;
}
.style-module__navMenuItem__ruD2No{
    border-top: 1px solid #D9D9D9;
    padding: 7px 0 8px 0;
}
.style-module__navMenuItemFirst__m67gqt{
    border-top: none;
    padding: 8px 15px;
}
.style-module__navMenuText__SVEPIX{
    line-height: 20px;
    font-weight: 300;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    color: #424242;
    margin: 0;
}
.style-module__navMenuTextGray__xILMtl{
    color: #9E9E9E;
}
.style-module__navMenuTextIcon__OhpXIp{
    display: none;
    gap: 10px;
    height: 24px;
}
.style-module__navMenuLink___HLqUa{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    -webkit-text-decoration: none;
    text-decoration: none;
    border: none;
    padding: 6px 15px;
    background-color: #ffffff;
}
.style-module__navMenuLinkIcon__TEivqr{
    gap: 10px;
}
.style-module__navMenuLinkWorkspace__MganBh{
    justify-content: space-between;
}
.style-module__navMenuWorkspaceIcons__HUQS8t{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
}
.style-module__navMenuLink___HLqUa:hover{
    color: #1976D2;
    background-color: #E3F2FD;
    border: none;

}
.style-module__navMenuIconButton__WGI4tX{
    border: none;
    background-color: inherit;
    margin: 0;
    padding: 0;
}
.style-module__active__oU1Mrn{
    background-color: #E3F2FD;
}

@media (max-width: 676px) {
    .style-module__navMenuTextIcon__OhpXIp {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navMenuList": `style-module__navMenuList__i3IjJ1`,
	"navMenuItem": `style-module__navMenuItem__ruD2No`,
	"navMenuItemFirst": `style-module__navMenuItemFirst__m67gqt`,
	"navMenuText": `style-module__navMenuText__SVEPIX`,
	"navMenuTextGray": `style-module__navMenuTextGray__xILMtl`,
	"navMenuTextIcon": `style-module__navMenuTextIcon__OhpXIp`,
	"navMenuLink": `style-module__navMenuLink___HLqUa`,
	"navMenuLinkIcon": `style-module__navMenuLinkIcon__TEivqr`,
	"navMenuLinkWorkspace": `style-module__navMenuLinkWorkspace__MganBh`,
	"navMenuWorkspaceIcons": `style-module__navMenuWorkspaceIcons__HUQS8t`,
	"navMenuIconButton": `style-module__navMenuIconButton__WGI4tX`,
	"active": `style-module__active__oU1Mrn`
};
export default ___CSS_LOADER_EXPORT___;
