import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ellipsize from "ellipsize";
import { I18n } from "react-redux-i18n";
import { closePopovers } from "../../helpers/common";
import onTabClose from "../../helpers/onTabClose";
import { ACCEPTED_ORDER } from "./../../constants/AuthorizedStatusTypes";
import HtmlTranslate from "./../HtmlTranslate";
import { Link } from "react-router-dom";
import "../../css/deprecated/popover.css";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import iconSet from "../../images/selection.json";
import IcomoonReact from "icomoon-react";
import { withAuthProvider } from "./../refactored/AuthProvider";
import { PopupCreateWorkspace } from "../refactored/Popups/PopupCreateWorkspace/PopupCreateWorkspace";
import { withRouter } from "../../containers/CustomerApp";
import {
  acceptInviteAction,
  deleteInviteAction,
  getWorkspaceAction,
} from "../../thunks/newThunks/workspace/workspaceAsyncActions";
import classNames from "classnames";
import style from "./css/style.module.css";
import { getMeAction } from "../../thunks/newThunks/user/userAsyncActions";
class UserMenu extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    feedback: PropTypes.bool,
    appName: PropTypes.string,
  };

  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
    this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
    this.handleUserMenuLinkClick = this.handleUserMenuLinkClick.bind(this);
    this.handleOpenWorkspacePopup = this.handleOpenWorkspacePopup.bind(this);
    this.handleCloseWorkspacePopup = this.handleCloseWorkspacePopup.bind(this);
    this.state = {
      workspacePopup: false,
      userName: "",
    };
  }

  componentDidMount() {
    const { activeWorkspace, user } = this.props;
    this.setState({ userName: activeWorkspace?.name || user.fullName || user.name });
    document.body.addEventListener("click", function () {
      const userMenu = document.getElementById("user-menu");
      const userMenuTrigger = document.getElementById("user-menu-trigger");

      if (userMenu) {
        userMenuTrigger.classList.remove("active");
      }
    });
  }
  componentDidUpdate(prevProps) {
    const { activeWorkspace, user } = this.props;
    if (prevProps.user !== user) {
      this.setState({
        userName: activeWorkspace?.name || user.fullName || user.name,
      });
    }
  }
  handleUserMenuClick() {
    const userMenu = document.getElementById("user-menu");
    const userMenuTrigger = document.getElementById("user-menu-trigger");

    if (!userMenu) {
      userMenuTrigger.classList.add("active");
    } else {
      userMenuTrigger.classList.remove("active");
    }
  }

  handleUserMenuLinkClick() {
    closePopovers();
  }

  handleClick() {
    if (this.props.desktopStatus === ACCEPTED_ORDER) {
      let message = I18n._translate("TRANSLATOR_APP.DESKTOP_PAGE.CONFIRM_LOGOUT");
      closePopovers();
      setTimeout(() => {
        if (confirm(message)) {
          window.removeEventListener("beforeunload", onTabClose);

          this.props?.auth?.logout();
        }
      }, 0);
    } else {
      this.props?.auth?.logout();
    }
  }
  async handleActiveWorkSpace(workspaceId, link) {
    const { router, getWorkspace } = this.props;
    await getWorkspace(workspaceId);
    const currentPath = window.location.pathname;
    const currentPathArray = currentPath.split("/");
    currentPathArray.splice(1, 1, link);
    router.navigate(currentPathArray.join("/"));
    this.handleUserMenuLinkClick();
  }
  handleInviteWorkspace({ workspaceId, inviteType, workspaceLink }) {
    const { acceptInvite, deleteInvite, getMe } = this.props;
    const invitationType = {
      accept: acceptInvite,
      delete: deleteInvite,
    };
    invitationType[inviteType](workspaceId).then(() => {
      getMe();
      if (inviteType === "accept") {
        this.handleActiveWorkSpace(workspaceId, workspaceLink);
      }
    });
  }
  handleOpenWorkspacePopup() {
    this.setState({
      workspacePopup: true,
    });
  }
  handleCloseWorkspacePopup() {
    this.setState({
      workspacePopup: false,
    });
  }
  render() {
    const { user, feedback, activeWorkspace } = this.props;
    const { workspaceInfoList } = user;
    const supportLinkDisplay = feedback ? "block" : "none";
    const appName = this.props.appName ? this.props.appName : "customer";
    const customerName = user.fullName ? user.fullName : user.name;
    let langAppName = appName.toUpperCase() + "_APP";

    const shouldShowStarsForTranslator =
      user &&
      user.rolesList &&
      Array.isArray(user.rolesList) &&
      user.rolesList.includes(3) &&
      window.location.href.includes("/translator/");

    const popover = (
      <Popover className="user-popover" id="user-menu">
        <ul className={style.navMenuList}>
          <li className={classNames(style.navMenuItem, style.navMenuItemFirst)}>
            <p title={customerName} className={style.navMenuText}>
              {ellipsize(customerName, 20)}
            </p>
            <p title={user.email} className={classNames(style.navMenuText, style.navMenuTextGray)}>
              {ellipsize(user.email, 30)}
            </p>
            {shouldShowStarsForTranslator && (
              <div className={style.navMenuTextIcon}>
                <IcomoonReact color={"#545454"} iconSet={iconSet} size={20} icon={"star-menu"} />
                <p className={style.navMenuText}>{user.starState.available}</p>
              </div>
            )}
          </li>
          {appName === "customer" && window.location.pathname !== "/select-role" && (
            <li className={style.navMenuItem}>
              {workspaceInfoList?.length > 0 && (
                <>
                  <button
                    className={classNames(style.navMenuLink, style.navMenuText, {
                      [style.active]: !activeWorkspace?.id,
                    })}
                    onClick={e => {
                      this.handleActiveWorkSpace("", "customer");
                    }}
                  >
                    {customerName + " (Personal)"}
                  </button>
                  {workspaceInfoList.map(elem => {
                    if (elem.role) {
                      return (
                        <button
                          className={classNames(style.navMenuLink, style.navMenuText, {
                            [style.active]: elem.workspacesId === Number(activeWorkspace?.id),
                          })}
                          key={elem.workspacesId}
                          value={elem.workspacesId}
                          onClick={e => {
                            this.handleActiveWorkSpace(elem.workspacesId, elem.link);
                          }}
                        >
                          {elem.workspacesName}
                        </button>
                      );
                    } else {
                      return (
                        <div
                          key={elem.workspacesId}
                          className={classNames(style.navMenuLink, style.navMenuText, style.navMenuLinkWorkspace)}
                        >
                          <p>{elem.workspacesName}</p>
                          <div className={style.navMenuWorkspaceIcons}>
                            <button
                              className={style.navMenuIconButton}
                              onClick={() =>
                                this.handleInviteWorkspace({
                                  workspaceId: elem.workspacesId,
                                  inviteType: "accept",
                                  workspaceLink: elem.link,
                                })
                              }
                            >
                              <IcomoonReact iconSet={iconSet} size={20} color={"#1E88E5"} icon={"on-list"} />
                            </button>
                            <button
                              className={style.navMenuIconButton}
                              onClick={() =>
                                this.handleInviteWorkspace({
                                  workspaceId: elem.workspacesId,
                                  inviteType: "delete",
                                  workspaceLink: elem.link,
                                })
                              }
                            >
                              <IcomoonReact iconSet={iconSet} size={20} color={"#545454"} icon={"trash"} />
                            </button>
                          </div>
                        </div>
                      );
                    }
                  })}
                </>
              )}
              <button
                className={classNames(style.navMenuLink, style.navMenuLinkIcon, style.navMenuText)}
                onClick={this.handleOpenWorkspacePopup}
              >
                <IcomoonReact iconSet={iconSet} size={16} color={"#868686"} icon={"sign-out-icon-copy"} />
                Add new workspace
              </button>
            </li>
          )}
          <li className={style.navMenuItem}>
            <Link
              to={`settings`}
              onClick={this.handleUserMenuLinkClick}
              className={classNames(style.navMenuLink, style.navMenuText)}
            >
              <HtmlTranslate value={`${langAppName}.SETTINGS_PAGE.PAGE_HEADING`} />
            </Link>
            <Link
              to={`support`}
              style={{ display: supportLinkDisplay }}
              onClick={this.handleUserMenuLinkClick}
              className={classNames(style.navMenuLink, style.navMenuText)}
            >
              <HtmlTranslate value={`SUPPORT_PAGE.PAGE_HEADING`} />
            </Link>
          </li>
          <li className={style.navMenuItem}>
            <Link
              href="#"
              onClick={this.handleClick}
              className={classNames(style.navMenuLink, style.navMenuLinkIcon, style.navMenuText)}
            >
              <IcomoonReact iconSet={iconSet} color={"#a4a4a4"} size={18} icon={"logout"} />
              <HtmlTranslate value={`HEADER.LOGOUT_LINK`} />
            </Link>
          </li>
        </ul>
      </Popover>
    );

    return (
      <>
        <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
          <a onClick={this.handleUserMenuClick} id="user-menu-trigger" className="header-user">
            <span className="user-icon">&nbsp;</span>
            <span title={this.state.userName} className="user-name">
              {this.state.userName}
            </span>
            <span className="drop-icon">&nbsp;</span>
          </a>
        </OverlayTrigger>
        <PopupCreateWorkspace isOpen={this.state.workspacePopup} onClose={this.handleCloseWorkspacePopup} />
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getWorkspace: workspaceId => dispatch(getWorkspaceAction({ id: workspaceId })),
    acceptInvite: workspaceId => dispatch(acceptInviteAction(workspaceId)),
    deleteInvite: workspaceId => dispatch(deleteInviteAction(workspaceId)),
    getMe: () => dispatch(getMeAction),
  };
}

function mapStateToProps(state) {
  return {
    activeWorkspace: state.me.me.data.activeWorkspace,
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAuthProvider(UserMenu)));
