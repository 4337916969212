import React, { Component } from "react";
import * as _ from "lodash";
import TableCell from "../TableCell/TableCell";
import CheckBox from "../../../refactored/CheckBox/CheckBox";
// eslint-disable-next-line no-unused-vars
import row from "./css/table-row.css";
import { definePenStyle } from "../../../../helpers/getStatusOrderString";
import { I18n } from "react-redux-i18n";
import { Link } from "react-router-dom";
import { formatCompletedPayout } from "../../../../helpers/formatCompletedPayout";
import { HandySvg } from "handy-svg";
import processIcon from "../../../../images/svg/process-icon.svg";
import errorIcon from "../../../../images/svg/error-icon.svg";
import iconSet from "../../../../images/selection.json";
import IcomoonReact from "icomoon-react";

import moment from "moment";
import { getDatePeriodAsString } from "../../../refactored/DateRangePicker/helpers";
import { formatDate } from "../../../../helpers/formatDate";
import { ORDER_FORMATS_STATUS } from "../../../../constants/Order";

const TABELS_IDS = {
  TRANSLATORS: "TRANSLATORS",
  PING: "PING",
  RATES: "RATES",
  PAYOUTS: "PAYOUTS",
  CUSTOMER_RATES: "CUSTOMER_RATES",
};

export default class TableRow extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.isChecked !== this.props.isChecked ||
      nextProps.content !== this.props.content ||
      nextProps.content.highlighted !== this.props.content.highlighted ||
      nextProps.trStyle !== this.props.trStyle ||
      nextProps.isUnread !== this.props.isUnread ||
      nextProps.columns !== this.props.columns
    );
  }

  static defaultProps = {
    rowStyleName: "row.base",
    checkedRowStyleName: "row.row-checked",
  };

  handleChange = (e, isChecked, isShiftClick) => {
    const { content, handleSelectedRow } = this.props;

    handleSelectedRow(content, isChecked, isShiftClick);
    e.stopPropagation();
    e.preventDefault();
  };

  handleRowClick = e => {
    const { onRowClick, content } = this.props;
    onRowClick && onRowClick(content, this.row, e);
  };

  handleCellClick = onCellClick => {
    const { content } = this.props;
    onCellClick && onCellClick(content);
  };

  getEditPenStyle = () => {
    const { content } = this.props;
    const { editTime, status } = content;

    return definePenStyle(status, editTime);
  };

  getPausedStyle = () => {
    const { content, showPausedIcon } = this.props;
    const { paused } = content;
    const pausedTitle = paused && I18n.t("ORDER_PAGE.PAUSED_ORDER_ICON_HINT");
    const defaultPausedProps = {
      isPaused: false,
      pausedTitle: false,
    };

    return showPausedIcon ? { isPaused: paused, pausedTitle: pausedTitle } : defaultPausedProps;
  };

  isFirst = item => {
    const { content } = this.props;
    const { isFirstOrder } = content;
    if (isFirstOrder && item.id === "id") {
      return true;
    }
  };

  isDiscount = item => {
    const { content } = this.props;
    const { discountId, tmAmount } = content;
    if ((discountId && item.id === "customerPrice") || (tmAmount && item.id === "customerPrice")) {
      return true;
    }
  };

  origin = item => {
    const { content } = this.props;
    const { origin } = content;
    const API = 1;
    if (origin === API && item.id === "customerEmail") {
      return true;
    }
  };

  isUnread = () => {
    return this.props.content.isUnread;
  };
  isInProgress = () => {
    const { content, idTable } = this.props;
    if (
      (content.status === 9 && idTable === "queue") ||
      this.props.content.fileFormationStatus === ORDER_FORMATS_STATUS[1] ||
      this.props.content.fileFormationStatus === ORDER_FORMATS_STATUS[2] ||
      this.props.content.fileFormationStatus === ORDER_FORMATS_STATUS[4]
    ) {
      return true;
    } else {
      return false;
    }
  };
  setTitleVacationIcon = vacation => {
    return vacation && getDatePeriodAsString(vacation, "Vacation");
  };
  isVacationWithin14Days = vacation => {
    if (vacation) {
      if (!vacation.startVacationPeriod) {
        return false;
      }
      const start = formatDate(vacation.startVacationPeriod);

      const currentDate = moment();
      const fourteenDaysFromNow = currentDate.clone().add(14, "days");

      return start.isBetween(currentDate, fourteenDaysFromNow, null, "[]");
    }
  };
  isVacationStarted = vacation => {
    if (vacation && vacation.startVacationPeriod) {
      const start = moment(formatDate(vacation.startVacationPeriod)).startOf("day");
      const currentDate = moment().startOf("day");
      return start.isSameOrBefore(currentDate);
    }
  };

  errorStatus = () => {
    const { content } = this.props;
    const isError = content.fileFormationStatus === ORDER_FORMATS_STATUS[4] || content.status === 9;
    let errorTitle = I18n.t("ORDER_PAGE.ERROR_NO_TARGET_FILE");

    if (content.status === 9) errorTitle = I18n.t("ORDER_PAGE.AI_TASK_FAILED");
    if (!isError) errorTitle = I18n.t("ORDER_PAGE.ERROR_IS_TARGET_FILE");
    return { isError, errorTitle };
  };
  getTableCell = () => {
    const {
      columns,
      content,
      isChecked,
      tdStyleName,
      cellStyleName,
      rowIsLink,
      idName,
      cellBtnHandler,
      idTable,
      onClickFileTitle,
    } = this.props;
    const isTranslatorsOrRates =
      idTable === TABELS_IDS.TRANSLATORS || idTable === TABELS_IDS.RATES || idTable === TABELS_IDS.CUSTOMER_RATES;
    const defaultLinkIconStyle = {
      borderBottom: "none",
      display: "flex",
      height: 36,
      width: 20,
    };

    return _.map(columns, (item, i) => {
      if (item.id === "checkBox") {
        return (
          <TableCell
            key={"checkBox" + i}
            tdClass={item.tdClass && item.tdClass}
            tdStyle={{
              padding: 0,
              paddingTop: 5,
              justifyContent: "center",
              alignItems: "center",
              width: 16,
              minWidth: 16,
              maxWidth: 16,
            }}
            style={item.style}
            content={<CheckBox isChecked={isChecked} handleChange={this.handleChange} />}
          />
        );
      } else if (item.id === "icon") {
        const id = content.id ? content.id : content[idName];
        const isUnread = this.isUnread(content);
        const isInProgress = this.isInProgress();
        const { isAddPen, editPenColor, editPenTitle } = this.getEditPenStyle(content);
        const { isPaused, pausedTitle } = this.getPausedStyle(content);
        const { isError, errorTitle } = this.errorStatus();

        const linkIconStyle = { ...defaultLinkIconStyle, padding: "0 3px" };
        const editPen = isAddPen && (
          <div title={editPenTitle} styleName={"row.pen-container"}>
            <div styleName={editPenColor} />
          </div>
        );

        const paused = isPaused && (
          <div title={pausedTitle} styleName={"row.pen-container"}>
            <IcomoonReact iconSet={iconSet} color={"#FF9800"} size={14} icon={"pause_shaded"} />
          </div>
        );

        const unread = isUnread && (
          <div title={I18n.t("ORDER_PAGE.NEW_COMMENT_ICON_HINT")} styleName={"row.unread-container"}>
            <IcomoonReact iconSet={iconSet} color={"#FF9800"} size={14} icon={"unread_dialog"} />
          </div>
        );
        const inProgress = isInProgress && (
          <div title={errorTitle} styleName={"row.pen-container"}>
            <HandySvg width={14} height={14} src={isError ? errorIcon : processIcon} />
          </div>
        );
        const isOnVacation = this.isVacationStarted(content.vacation);
        const onVacation = isOnVacation && (
          <div title={this.setTitleVacationIcon(content.vacation)} styleName={"row.pen-container"}>
            <IcomoonReact iconSet={iconSet} color={"#FF9800"} size={14} icon={"vacation"} />
          </div>
        );

        return (
          <TableCell
            key={"icon" + i}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            content={
              <div style={item.iconContainerStyle}>
                <Link
                  style={{ width: 12, ...linkIconStyle }}
                  to={rowIsLink + id}
                  ref={node => {
                    this.divEl = node;
                  }}
                >
                  {editPen}
                </Link>
                <Link
                  style={linkIconStyle}
                  to={rowIsLink + id}
                  ref={node => {
                    this.divEl = node;
                  }}
                >
                  {unread}
                </Link>
                <Link
                  style={linkIconStyle}
                  to={rowIsLink + id}
                  ref={node => {
                    this.divEl = node;
                  }}
                >
                  {inProgress}
                </Link>
                {idTable === TABELS_IDS.PING && (
                  <Link
                    style={linkIconStyle}
                    to={rowIsLink + id}
                    ref={node => {
                      this.divEl = node;
                    }}
                  >
                    {onVacation}
                  </Link>
                )}
                {!item.isNotPaused && (
                  <Link
                    style={linkIconStyle}
                    to={rowIsLink + id}
                    ref={node => {
                      this.divEl = node;
                    }}
                  >
                    {paused}
                  </Link>
                )}
              </div>
            }
          />
        );
      } else if (item.id === "notes") {
        const id = content.id ? content.id : content[idName];
        let paused = "";
        if ((content.paused || content.translatorPaused) && item.list && item.list.includes("paused")) {
          paused = (
            <div title={I18n.t("ORDER_PAGE.PAUSED_ORDER_ICON_HINT")} styleName={"row.pen-container"}>
              <IcomoonReact iconSet={iconSet} color={"#FF9800"} size={14} icon={"pause_shaded"} />
            </div>
          );
        }
        const isOnVacation = this.isVacationStarted(content.vacation);
        const isWithin14Days = idTable === TABELS_IDS.CUSTOMER_RATES && this.isVacationWithin14Days(content.vacation);
        const onVacation = (isOnVacation || isWithin14Days) && isTranslatorsOrRates && (
          <div title={this.setTitleVacationIcon(content.vacation)} styleName={"row.pen-container"}>
            <IcomoonReact iconSet={iconSet} color={!isWithin14Days ? "#FF9800" : ""} size={14} icon={"vacation"} />
          </div>
        );
        const linkIconStyle = { ...defaultLinkIconStyle, alignItems: "center", paddingRight: 6 };
        const test = !item.list || (item.list && item.list.includes("test")) ? item.format(content[item.id]) : "";
        return (
          <TableCell
            key={"icon" + i}
            style={{ paddingLeft: 0, paddingRight: 0, width: item.thStyle.width, alignItems: "center" }}
            title={content[item.id]}
            content={
              <div style={{ padding: 0, display: "flex", width: item.thStyle.width }}>
                <Link
                  style={linkIconStyle}
                  to={rowIsLink + id}
                  ref={node => {
                    this.divEl = node;
                  }}
                >
                  {test}
                </Link>
                <Link
                  style={linkIconStyle}
                  to={rowIsLink + id}
                  ref={node => {
                    this.divEl = node;
                  }}
                >
                  {paused}
                </Link>
                <Link
                  style={linkIconStyle}
                  to={rowIsLink + id}
                  ref={node => {
                    this.divEl = node;
                  }}
                >
                  {onVacation}
                </Link>
              </div>
            }
          />
        );
      } else if (item.id === "completed" && idTable === TABELS_IDS.PAYOUTS) {
        return (
          <TableCell
            key={"payouts" + i}
            style={item && item.style}
            content={formatCompletedPayout(content, () => cellBtnHandler(content.id, content))}
          />
        );
      } else if (!item.notDisplay) {
        const isFirst = this.isFirst(item);
        const isDiscount = this.isDiscount(item);
        const origin = this.origin(item);
        let itemContent = item.format
          ? item.option
            ? item.option(content)
            : item.content
              ? item.format(content)
              : item.format(content[item.id])
          : content[item.id];

        if (item.id === "languagesList" && Array.isArray(itemContent)) {
          itemContent = itemContent.length;
        }

        if (item.id === "filename" && typeof itemContent === "string" && itemContent && itemContent.length > 70) {
          itemContent = itemContent.substring(0, 70).trim() + "...";
        }

        return (
          <TableCell
            key={i}
            content={itemContent}
            id={content.id ? content.id : content[idName]}
            onCellClick={() => {
              item.onCellClick ? this.handleCellClick(item.onCellClick) : this.handleRowClick();
            }}
            isFirst={isFirst}
            isDiscount={isDiscount}
            origin={origin}
            style={
              item.optionStyleCell
                ? {
                    ..._.assign(item.optionStyleCell(content), item.style),
                  }
                : { ...item.style }
            }
            tdStyleName={tdStyleName}
            cellStyleName={cellStyleName}
            tdStyle={item.tdStyle && item.tdStyle(content)}
            tdClass={item.tdClass && item.tdClass}
            optionsFormat={item.optionsFormat}
            tooltipFormat={item.tooltipFormat}
            highlighted={content.highlighted}
            rowIsLink={rowIsLink}
            onClickEditFile={item.onClickEditFile && item.onClickEditFile}
            mainCharsCounter={item.mainCharsCounter && item.mainCharsCounter}
            subCharsCounter={item.subCharsCounter && item.subCharsCounter}
            draft={item.draft && item.draft}
            charsCounter={item.charsCounter}
            docsType={item.type && item.type}
            onClickFileTitle={onClickFileTitle}
          />
        );
      }
    });
  };

  render() {
    const { isChecked, rowStyleName, checkedRowStyleName, trStyle, isOnRowClick, content } = this.props;
    const tableCells = this.getTableCell();
    const highlightedRow = "row.base-green";
    const styleName = isChecked
      ? checkedRowStyleName
      : trStyle && trStyle(content)
        ? trStyle(content)
        : content.highlighted
          ? highlightedRow
          : rowStyleName;

    return (
      <tr ref={node => (this.row = node)} onClick={isOnRowClick && this.handleRowClick} styleName={styleName}>
        {tableCells}
      </tr>
    );
  }
}
